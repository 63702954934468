exports.components = {
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-bronchiectasis-exclusive-videos-js": () => import("./../../../src/pages/bronchiectasis-exclusive-videos.js" /* webpackChunkName: "component---src-pages-bronchiectasis-exclusive-videos-js" */),
  "component---src-pages-bronchiectasis-pathophysiology-js": () => import("./../../../src/pages/bronchiectasis-pathophysiology.js" /* webpackChunkName: "component---src-pages-bronchiectasis-pathophysiology-js" */),
  "component---src-pages-chest-2023-js": () => import("./../../../src/pages/CHEST2023.js" /* webpackChunkName: "component---src-pages-chest-2023-js" */),
  "component---src-pages-disease-overview-js": () => import("./../../../src/pages/disease-overview.js" /* webpackChunkName: "component---src-pages-disease-overview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-server-error-js": () => import("./../../../src/pages/internal-server-error.js" /* webpackChunkName: "component---src-pages-internal-server-error-js" */),
  "component---src-pages-page-not-found-js": () => import("./../../../src/pages/page-not-found.js" /* webpackChunkName: "component---src-pages-page-not-found-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-stay-informed-js": () => import("./../../../src/pages/stay-informed.js" /* webpackChunkName: "component---src-pages-stay-informed-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-take-action-js": () => import("./../../../src/pages/take-action.js" /* webpackChunkName: "component---src-pages-take-action-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

